<template>
  <v-app>
    <blank-view />
  </v-app>
</template>

<script>
export default {
  name: "LayoutsBlank",
  components: {
    BlankView: () => import("./View.vue"),
  },
};
</script>
